import {
  Text,
  Flex,
  Heading,
  Container,
  VStack,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  Skeleton,
  useToast,
  useColorModeValue,
  Button
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';
import Navbar from '../components/Navbar';

import style from './Home.module.css';
import panelStyle from '../components/CardPanel/CardPanel.module.css';

import {
  Bot,
  BotOff,
  Cloud,
  CloudOff,
  Construction,
  Grid2X2,
  Hand,
  MessageCircleWarning,
  Notebook,
  NotebookText,
  Power,
  PowerOff,
  TriangleAlert
} from 'lucide-react';

const GetSystemModeText = (systemMode: string) => {
  if (systemMode === 'RUN') {
    return 'AS is running';
  } else if (systemMode === 'SVC') {
    return 'AS In Service Mode';
  } else if (systemMode === 'DSTP') {
    return 'AS is stopping';
  } else if (systemMode === 'STP') {
    return 'AS is stopped';
  } else if (systemMode === 'ALT') {
    return 'AS in Alert Mode';
  } else {
    return 'Unknown mode';
  }
};

const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

const OrganizationOverview = ({ organization }: any) => {
  const successColor = useColorModeValue(
    'var(--chakra-colors-green-600)',
    'var(--chakra-colors-green-200)'
  );
  const errorColor = useColorModeValue(
    'var(--chakra-colors-red-500)',
    'var(--chakra-colors-red-200)'
  );
  const warningColor = useColorModeValue(
    'var(--chakra-colors-yellow-600)',
    'var(--chakra-colors-yellow-400)'
  );

  return (
    <LinkBox className={panelStyle.panel}>
      <LinkOverlay href={`organization?organization-id=${organization.id}`}>
        <Heading
          color={useColorModeValue('navy.900', 'navy.100')}
          lineHeight={1}
          fontWeight={'normal'}
          fontSize={{ base: 'xl', sm: '2xl' }}
          marginBlockEnd={'0.75rem'}
        >
          {organization['name']}
        </Heading>

        <SimpleGrid
          gridTemplateColumns={'repeat(auto-fit, minmax(200px, 1fr))'}
          gap="0.75rem"
        >
          {organization.stub_connected !== null ? (
            <Flex className={style.orgStat}>
              {organization.stub_connected ? (
                <Cloud size={20} color={successColor} />
              ) : organization.stub_connected === false ? (
                <CloudOff color={errorColor} size={20} />
              ) : (
                ''
              )}
              <Text
                color={organization.stub_connected ? successColor : errorColor}
                fontSize={'l'}
                fontWeight={'300'}
                ml={2}
              >
                {organization.stub_connected
                  ? 'Stub connected ' +
                    (organization['stub_version']
                      ? '(v' + organization['stub_version'] + ')'
                      : '')
                  : organization.stub_connected === false
                    ? 'Stub disconnected'
                    : ''}
              </Text>
            </Flex>
          ) : (
            ''
          )}

          {organization.stub_connected ? (
            <>
              <Flex className={style.orgStat}>
                {organization.system_mode === 'RUN' ? (
                  <Power
                    className={style.statusIcon}
                    color={successColor}
                    size={20}
                  />
                ) : organization.system_mode === 'STP' ? (
                  <PowerOff
                    className={style.statusIcon}
                    color={errorColor}
                    size={20}
                  />
                ) : organization.system_mode === 'SVC' ? (
                  <Construction
                    className={style.statusIcon}
                    color={warningColor}
                    size={20}
                  />
                ) : organization.system_mode === 'ALT' ? (
                  <TriangleAlert
                    className={style.statusIcon}
                    color={'var(--chakra-colors-red-500'}
                    size={20}
                  />
                ) : organization.system_mode === 'DSTP' ? (
                  <PowerOff
                    className={style.statusIcon}
                    color={errorColor}
                    size={20}
                  />
                ) : (
                  ''
                )}
                <Text
                  color={
                    organization.system_mode === 'RUN'
                      ? successColor
                      : organization.system_mode === 'SVC'
                        ? warningColor
                        : organization.system_mode === 'ALT'
                          ? 'var(--chakra-colors-red-500)'
                          : errorColor
                  }
                  fontSize={'l'}
                  fontWeight={'300'}
                  ml={2}
                >
                  {GetSystemModeText(organization.system_mode)}
                </Text>
              </Flex>

              <Flex className={style.orgStat}>
                {organization.robot_unavailable_count ? (
                  <BotOff size={20} color={errorColor} />
                ) : (
                  <Bot size={20} color={successColor} />
                )}
                <Text
                  color={
                    organization.robot_unavailable_count
                      ? errorColor
                      : successColor
                  }
                  fontSize={'l'}
                  fontWeight={'300'}
                  ml={2}
                >
                  {organization.robot_unavailable_count
                    ? pluralize(organization.robot_unavailable_count, 'bot') +
                      ' unavailable'
                    : 'Bots are OK'}
                </Text>
              </Flex>

              <Flex className={style.orgStat}>
                {organization.robot_has_error_count ? (
                  <MessageCircleWarning size={20} color={errorColor} />
                ) : (
                  <MessageCircleWarning size={20} color={successColor} />
                )}
                <Text
                  color={
                    organization.robot_has_error_count
                      ? errorColor
                      : successColor
                  }
                  fontSize={'l'}
                  fontWeight={'300'}
                  ml={2}
                >
                  {organization.robot_has_error_count
                    ? pluralize(organization.robot_has_error_count, 'bot error')
                    : 'No bot errors'}
                </Text>
              </Flex>
            </>
          ) : organization.stub_connected !== null ? (
            <Flex className={style.orgStat}>
              <PowerOff size={20} color={errorColor} />
              <Text color={errorColor} fontSize={'l'} fontWeight={'300'} ml={2}>
                No AS data
              </Text>
            </Flex>
          ) : (
            ''
          )}
          {organization.system_mode !== null ? (
            <Flex
              className={style.orgStat}
              color={
                organization.used_compartments_ratio > 0.99 ? errorColor : ''
              }
            >
              <Grid2X2 size={20} />
              <Text fontSize={'l'} fontWeight={'300'} ml={2}>
                {(organization.used_compartments_ratio * 100).toFixed(1)}% comp.
                used
              </Text>
            </Flex>
          ) : (
            ''
          )}
          <Flex className={style.orgStat}>
            <Hand size={20} />
            <Text fontSize={'l'} fontWeight={'300'} ml={2}>
              Picks 1h (12h): {organization.tasks_completed_last_hour} (
              {organization.tasks_completed_last_12_hour})
            </Text>
          </Flex>
          <Flex className={style.orgStat}>
            <Notebook size={20} />
            <Text fontSize={'l'} fontWeight={'300'} ml={2}>
              {pluralize(organization.orders_total, 'order')}
            </Text>
          </Flex>
          <Flex className={style.orgStat}>
            <NotebookText size={20} />
            <Text fontSize={'l'} fontWeight={'300'} ml={2}>
              {pluralize(organization.orderlines_total, 'order line')}
            </Text>
          </Flex>
        </SimpleGrid>
      </LinkOverlay>
    </LinkBox>
  );
};

function compare(a: any, b: any) {
  if (a.badness_score > b.badness_score) {
    return -1;
  }
  if (a.badness_score < b.badness_score) {
    return 1;
  }
  return a.id > b.id ? 1 : -1;
}

function navigateToCreate() {
  return (window.location.href = window.location.href + 'create-organization');
}

const Dashboard = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const api = useAxios();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/dashboard/summary');
        const sorted_organizations = response.data.organizations.sort(compare);
        setOrganizations(sorted_organizations);
        setLoading(false);
      } catch {
        setOrganizations([]);
        setLoading(false);
        toast({
          title: 'Error',
          description: 'Failed to fetch dashboard data',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 10000);
    return () => clearInterval(id);
  }, []);

  return (
    <Navbar>
      <Container maxW="100%" p={0}>
        <VStack spacing={6} align="stretch">
          <Flex justifyContent="space-between" alignItems={'center'}>
            <Heading
              color={useColorModeValue('navy.800', 'zest.400')}
              fontSize={{ base: '2xl', sm: '3xl' }}
            >
              Home
            </Heading>

            <Button
              variant={'primaryAction'}
              onClick={navigateToCreate}
              size={'lg'}
            >
              Create organization
            </Button>
          </Flex>
          <Skeleton isLoaded={!loading}>
            <SimpleGrid
              gap={'1.5rem'}
              placeContent={'start'}
              templateColumns="repeat(auto-fit, minmax(max(100% / 4 - 0.25rem, min(250px, 100%)), 1fr));"
            >
              {organizations.map(organization => (
                <OrganizationOverview
                  key={organization['id']}
                  organization={organization}
                />
              ))}
            </SimpleGrid>
          </Skeleton>
        </VStack>
      </Container>
    </Navbar>
  );
};

export default Dashboard;
